<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h3 class="m-0">Plan de formations</h3>
      <div class="d-flex">
        <BaseButton
          class="btn btn-primary"
          v-tooltip="'Rechercher les formations'"
          @click="getFormations"
          :loading="loading"
          icon="search"
          text="Rechercher"
        ></BaseButton>
        <download-excel
          v-if="formations.length > 0"
          :data="formationsExport"
          class="btn btn-primary cursor-pointer ml-2"
          name="plan_formations.xls"
        >
          Exporter (.xls)
          <span class="badge badge-warning">{{ formations.length }}</span>
        </download-excel>
      </div>
    </div>
    <div class="d-flex flex-column flex-lg-row">
      <filtre-select
        class="mr-3 mb-3"
        inputText="Etat"
        v-model.number="filterEtat"
        :options="data.formationEtats"
        :disabled="false"
      ></filtre-select>
      <filtre-select
        class="mr-3 mb-3"
        inputText="Type"
        v-model.number="filterType"
        :options="data.formationTypes"
        :disabled="false"
      ></filtre-select>
      <filtre-select
        class="mr-3 mb-3 mr-lg-0"
        inputText="Libellé"
        v-model.number="filterLibId"
        :options="data.formationLibelles"
        :disabled="false"
      ></filtre-select>
    </div>
    <div class="d-flex flex-column flex-lg-row">
      <BaseInputDatePickerMonth
        v-background-3
        class="shadow-sm rounded p-2 pl-3 pr-3 mr-3 mb-3"
        v-model="filterDateDebut"
        inputText="Début de la formation"
        :iconActive="true"
        minimumView="month"
      ></BaseInputDatePickerMonth>
      <BaseInputDatePickerMonth
        v-background-3
        class="shadow-sm rounded p-2 pl-3 pr-3 mr-3 mb-3"
        v-model="filterDateFin"
        inputText="Fin de la formation"
        :iconActive="true"
        minimumView="month"
      ></BaseInputDatePickerMonth>
      <BaseInputDatePickerMonth
        v-background-3
        class="shadow-sm rounded p-2 pl-3 pr-3 mb-3"
        v-model="filterDateValidite"
        inputText="Validité de la formation"
        :iconActive="true"
        minimumView="month"
      ></BaseInputDatePickerMonth>
    </div>

    <div class="d-flex flex-column flex-lg-row">
      <div class="w-100 p-2 pl-3 pr-3 mr-3 mb-3">La recherche par période utilise la date de début de formation.</div>
      <BaseInputDatePickerMonth
        v-background-3
        class="shadow-sm rounded p-2 pl-3 pr-3 mr-3 mb-3"
        v-model="filterDebutPeriode"
        inputText="Début de la période"
        :iconActive="true"
        minimumView="month"
      ></BaseInputDatePickerMonth>
      <BaseInputDatePickerMonth
        v-background-3
        class="shadow-sm rounded p-2 pl-3 pr-3 mb-3"
        v-model="filterFinPeriode"
        inputText="Fin de la période"
        :iconActive="true"
        minimumView="month"
      ></BaseInputDatePickerMonth>
    </div>

    <div v-if="!loading">
      <div v-for="frm in formations" :key="frm.id" class="mb-2 p-3 rounded shadow-sm" v-background-3>
        <div class="d-flex flex-column flex-md-row align-items-center w-100 justify-content-between">
          <div>
            <div>
              <strong>
                <description-from-list
                  :value="frm.formation_libelle_id"
                  :data="data.formationLibelles"
                  text="Aucun libellé"
                ></description-from-list>
              </strong>
            </div>
            <div>
              <description-from-list
                class="text-muted"
                :value="frm.formation_organisme_id"
                :data="data.formationOrganismes"
                text="Aucun organisme"
              ></description-from-list>
            </div>
            <div style="line-height: 1.2">
              <small>
                <description-from-list
                  class=""
                  :value="frm.formation_etat_id"
                  :data="data.formationEtats"
                  text="Aucun état"
                ></description-from-list>
                {{ dateSimple(frm.formation_date_debut) }} -
                {{ dateSimple(frm.formation_date_fin) }}
              </small>
            </div>
            <div style="line-height: 1.2">
              <small>
                {{ formationValiditeText(frm.formation_date_validite) }}
              </small>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <router-link
              :to="{
                name: 'sirh_collaborateur_formations',
                params: { id: frm.collaborateur_lt.id },
              }"
              class="d-flex align-items-center hover-item w-100 p-2 pl-3 pr-3 cursor-pointer rounded"
              tag="div"
              style="min-width: 17rem"
            >
              <AppAvatar
                class="mr-2"
                :url="frm.collaborateur_lt.collaborateur_avatar"
                propSize="3rem"
                ref="avatar"
              ></AppAvatar>
              <div class="mr-1">
                {{ frm.collaborateur_lt.collaborateur_nom }}
              </div>
              <div class="mr-1">
                {{ frm.collaborateur_lt.collaborateur_prenom }}
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div></div>
    <!-- LOADER -->
    <div class="d-flex flex-column flex-md-row align-items-center" v-if="loading">
      <loader class="m-5"></loader>
      <div>
        <h2 class="m-0">Chargement...</h2>
        <span>Cela peut prendre quelques minutes</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DescriptionFromList from "@/components/bases/DescriptionFromList";
import AppAvatar from "@/components/bases/Avatar.vue";
import date from "@/mixins/date.js";
import FiltreSelect from "@/components/collaborateurs/recherche/FiltreSelect.vue";
import BaseInputDatePickerMonth from "@/components/bases/InputDatePickerMonth.vue";
import Loader from "@/components/bases/Loader.vue";
import BaseButton from "@/components/bases/Button.vue";

export default {
  data() {
    return {
      loading: false,
      formations: [],
      filterDateDebut: null,
      filterDateFin: null,
      filterDateValidite: null,
      filterType: null,
      filterEtat: null,
      filterLibId: null,
      filterDebutPeriode: null,
      filterFinPeriode: null,
    };
  },
  mixins: [date],
  components: {
    DescriptionFromList,
    AppAvatar,
    FiltreSelect,
    BaseInputDatePickerMonth,
    Loader,
    BaseButton,
  },
  props: {},
  methods: {
    descriptionById(list, id) {
      const index = this.data[list].findIndex((x) => x.id === id);
      if (this.data && this.data[list][index]) return this.data[list][index].description;
      else return "";
    },
    formationValiditeText(frmDate) {
      if (!frmDate) return "Aucune date de validité";
      if (this.$moment(frmDate) >= this.$moment()) return "Valide jusqu'au " + this.dateSimple(frmDate);
      if (this.$moment(frmDate) < this.$moment()) return "N'est plus valide depuis le " + this.dateSimple(frmDate);
    },
    getFormations() {
      this.loading = true;

      let debut = this.filterDateDebut !== null ? this.$moment(this.filterDateDebut).format("YYYY-MM-DD") : null;
      let fin = this.filterDateFin !== null ? this.$moment(this.filterDateFin).format("YYYY-MM-DD") : null;
      let validite =
        this.filterDateValidite !== null ? this.$moment(this.filterDateValidite).format("YYYY-MM-DD") : null;

      this.$http
        .get("/plan_formation", {
          params: {
            etat: this.filterEtat,
            type: this.filterType,
            libelle: this.filterLibId,
            debut: debut,
            fin: fin,
            validite: validite,
            debut_periode: this.filterDebutPeriode,
            fin_periode: this.filterFinPeriode,
          },
        })
        .then((response) => (this.formations = response.data))
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    ...mapGetters({ data: "collaborateurs/data" }),
    formationsExport() {
      if (!this.formations || this.formations.length == 0) return [];

      console.log(this.formations);

      let __frmExport = [];
      let __frmFiltered = this.formations;

      __frmFiltered.forEach((_frm) => {
        // Listes
        let contrats = [];
        let contratVersions = [];
        let contratVersions2 = [];

        // Contrat dépendant des dates de formation
        let selectedContrat = {};
        let selectedVersion = {};

        // Contrat actuels (derniers)
        let selectedVersionMax = {};

        if (_frm && _frm.collaborateur_lt && _frm.collaborateur_lt.collaborateur_contrats) {
          // Liste des contrats du collaborateur
          contrats = _frm.collaborateur_lt.collaborateur_contrats;

          // Boucle sur les contrats du collaborateur
          contrats.forEach((contrat) => {
            if (contrat && contrat.contrat_date_debut) {
              // *****************************************************************
              // CONTRAT PENDANT LES DATES DE FORMATION
              // *****************************************************************

              if (_frm.formation_date_debut && contrat.contrat_date_debut <= _frm.formation_date_debut) {
                if (contrat.contrat_date_fin >= _frm.formation_date_debut || contrat.contrat_date_fin == null) {
                  // CONTRAT CORRESPONDANT AUX DATES DE FORMATION
                  selectedContrat = contrat;
                  if (selectedContrat && selectedContrat.contrat_version) {
                    // VERSIONS CORRESPONDANTES AU CONTRAT (DATE DE FORMATION)
                    contratVersions = selectedContrat.contrat_version;
                    if (contratVersions) {
                      let max_dtObj = this.$moment(contratVersions[0].date_effet);
                      let indexVersionMaxDate = 0;
                      // Identifier l'index max sur la date d'effet
                      contratVersions.forEach((dt, index) => {
                        if (this.$moment(dt.date_effet) > max_dtObj) {
                          max_dtObj = this.$moment(dt.date_effet);
                          indexVersionMaxDate = index;
                        }
                      });
                      // Dernière version basée sur la date d'effet
                      selectedVersion = contratVersions[indexVersionMaxDate];
                    } else {
                      selectedVersion = {};
                    }
                  }
                }
              }
            } else {
              selectedVersion = {};
            }
          });

          // *****************************************************************
          // CONTRAT ACTUEL
          // *****************************************************************
          if (contrats[0] && contrats[0].contrat_date_debut) {
            let max_dtObj1 = this.$moment(contrats[0].contrat_date_debut);
            let indexContratMaxDate = 0;

            // Identifier l'index max sur la date de début
            contrats.forEach((dt, index) => {
              if (dt && dt.contrat_date_debut && this.$moment(dt.contrat_date_debut) > max_dtObj1) {
                max_dtObj1 = this.$moment(dt.contrat_date_debut);
                indexContratMaxDate = index;
              }
            });

            // Get versions et le dernier contrat
            if (contrats[indexContratMaxDate].contrat_version) {
              contratVersions2 = contrats[indexContratMaxDate].contrat_version;
            }
          }

          // -----------------------------------------------------------------------------------
          // ANALYSE DES VERSIONS DU CONTRAT
          // -----------------------------------------------------------------------------------
          // Identifier la dernière version si plusieurs versions d'un contrat (sur date d'effet)
          if (contratVersions2.length >= 1) {
            let max_dtObj2 = this.$moment(contratVersions2[0].date_effet);
            let indexVersionMaxDate2 = 0;
            // Identifier l'index max sur la date d'effet
            contratVersions2.forEach((dt, index) => {
              if (this.$moment(dt.date_effet) > max_dtObj2) {
                max_dtObj2 = this.$moment(dt.date_effet);
                indexVersionMaxDate2 = index;
              }
            });
            // Dernière version basée sur la date d'effet
            selectedVersionMax = contratVersions2[indexVersionMaxDate2];
          }
        }
        let _societe = "Aucune société";
        let _societe2 = "Aucune société";

        if (selectedVersion && selectedVersion.contrat_siret && selectedVersion.contrat_siret.societe)
          _societe = selectedVersion.contrat_siret.societe;

        if (selectedVersionMax && selectedVersionMax.contrat_siret && selectedVersionMax.contrat_siret.societe)
          _societe2 = selectedVersionMax.contrat_siret.societe;

        if (_frm && _frm.formation_montant_total_ark && _frm.formation_montant_total_ark == null)
          _frm.formation_montant_total_ark = _frm.formation_montant_arkadia - _frm.formation_montant_opca;

        __frmExport.push({
          id: _frm.id,
          collaborateur: _frm.collaborateur_lt.collaborateur_nom + " " + _frm.collaborateur_lt.collaborateur_prenom,
          ["collaborateur actif"]: _frm.collaborateur_lt.collaborateur_actif == true ? "Oui" : "Non",
          ["formation réalisée avec Arkadia"]: _frm.formation_avec_arkadia == true ? "Oui" : "Non",
          ["société du collab pendant la formation"]: _societe,
          ["société du collab actuellement"]: _societe2,
          etat: this.descriptionById("formationEtats", _frm.formation_etat_id),
          organisme: this.descriptionById("formationOrganismes", _frm.formation_organisme_id),
          type: this.descriptionById("formationTypes", _frm.formation_type_id),
          libelle: this.descriptionById("formationLibelles", _frm.formation_libelle_id),
          attestation: _frm.formation_attestation,
          ["date de début"]: _frm.formation_date_debut,
          ["date de fin"]: _frm.formation_date_fin,
          ["date de validité"]: _frm.formation_date_validite,
          ["N° facture"]: _frm.formation_facture_ref,
          ["nombre heures"]: _frm.formation_nbre_heures,
          ["formation obligatoire"]: _frm.formation_obligatoire,
          ["société pour la facturation"]: this.descriptionById("societes", _frm.formation_societe_id),
          opco: this.descriptionById("formationOpco", _frm.formation_opco_id),
          ["montant"]: _frm.formation_montant_arkadia,
          ["montant OPCA"]: _frm.formation_montant_opca,
          ["montant Arkadia"]: _frm.formation_montant_total_ark,
          commentaire: _frm.formation_commentaire,
        });
      });
      return __frmExport;
    },
  },
  mounted() {
    //this.getFormations();
  },
};
</script>
<style lang="css"></style>
